<!--选择器组件-->
<template>
  <div class="container">
    <div tabindex="0" class="select" @click="changeOpen" @blur="blur">
      <div class="placeholder" v-if="isActive.title == undefined">
        {{ placeholder }}
      </div>
      <div class="name" v-else>{{ isActive.title }}</div>
      <div class="icon">
        <svg
          viewBox="64 64 896 896"
          :class="open ? 'svg' : 'svg-top'"
          fill="rgba(0, 0, 0, 0.3)"
        >
          <path
            d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"
          ></path>
        </svg>
      </div>
      <div
        :class="open ? 'nulllist' : 'nulllist-hidden'"
        v-if="list.length == 0"
      >
        <div class="nulllist-svg">
          <svg width="64" height="41" viewBox="0 0 64 41">
            <g transform="translate(0 1)" fill="none" fillRule="evenodd">
              <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
              <g fillRule="nonzero" stroke="#D9D9D9">
                <path
                  d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                ></path>
                <path
                  d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  fill="#FAFAFA"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <span class="nulllist-font">暂无数据</span>
      </div>
      <div :class="open ? 'list' : 'list-show'" v-else>
        <ul class="list-ul" @mouseenter="enterIcon" @mouseleave="leaveIcon">
          <li
            :class="selectStyle(item)"
            @click="checked(item)"
            v-for="item in list"
            :key="item.key"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    isActive: {
      default: () => ({
        title: undefined,
        key: undefined,
      }),
    },
  },
  data() {
    return {
      open: false,
      hover: false,
    };
  },
  methods: {
    selectStyle(index) {
      if (this.hover) {
        return this.isActive.key == index.key
          ? "list-li-checked-hover"
          : "list-li";
      } else {
        return this.isActive.key == index.key ? "list-li-checked" : "list-li";
      }
    },
    checked(index) {
      this.$emit("select", index);
    },
    changeOpen() {
      this.open = !this.open;
    },
    blur() {
      this.open = false;
    },
    enterIcon() {
      this.hover = true;
    },
    leaveIcon() {
      this.hover = false;
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 32px;
  background: #fff;
  border-radius: 4px;
}
.select {
  position: relative;
  width: 100%;
  height: 32px;
  line-height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.select:hover {
  border-color: #707bc0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.select:focus {
  border-color: #707bc0;
  box-shadow: 0 0 0 2px rgba(112, 123, 192, 0.2);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: none;
}
.placeholder {
  height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.3);
  margin-left: 10px;
  margin-right: 30px;
}
.name {
  height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin-left: 10px;
  margin-right: 30px;
}
.svg {
  width: 12px;
  height: 12px;
  transition: all 0.3s;
  transform: rotate(180deg);
}
.svg-top {
  width: 12px;
  height: 12px;
  transition: all 0.3s;
}
.icon {
  position: absolute;
  width: 12px;
  height: 12px;
  line-height: 12px;
  top: 50%;
  margin-top: -6px;
  right: 8px;
}
.nulllist {
  position: absolute;
  width: 100%;
  padding: 20px 0px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  margin-top: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 99;
  transition: all 0.3s;
}
.nulllist-hidden {
  height: 0px;
  overflow: hidden;
  transition: all 0.3s;
}
.nulllist-svg {
  width: 64px;
  height: 41px;
  line-height: 41px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.nulllist-font {
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
}
.list {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 250px;
  border-radius: 4px;
  margin-top: 3px;
  transition: all 0.3s;
}
.list-show {
  height: 0;
  transition: all 0.3s;
  overflow: hidden;
}
.list-ul {
  width: 100%;
  max-height: 250px;
  margin: 0;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 5px 0px;
}
.list-li {
  width: 100%;
  overflow: hidden;
  padding: 5px 15px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.list-li-checked {
  width: 100%;
  overflow: hidden;
  padding: 5px 15px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #f0f4ff;
  font-weight: 600;
}
.list-li-checked-hover {
  width: 100%;
  overflow: hidden;
  padding: 5px 15px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #f8f6f6;
  font-weight: 600;
}
.list-li:hover {
  background-color: #f0f4ff;
  transition: all 0.3s;
}
</style>