<!--门禁模块--通行模式页-->
<template>
  <div id="trafficpatterns" ref="trafficpatterns" v-loading="loading" :element-loading-text="$t('trafficpatterns.a1')" >
    <!-- 侧边栏 -->
    <div class="aside" ref="container">
      <div class="aside-search">
        <a-input-search v-model="searchValue" :placeholder="$t('trafficpatterns.a45')" style="width: 100%" allow-clear/>
      </div>
      <div class="aside-menu">
        <a-empty v-if="list.length === 0" />
        <ul>
          <li v-for="(item, index) in list" :key="index" :title="item.title" :class="menuSelect == index ? 'li-item-select' : 'li-item'" @click="getMsgFormSon(item, index)">
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
    <!-- 右侧主要内容 -->
    <div class="right">
      <div class="main" v-if="id">
        <my-headertitle>{{ $t("trafficpatterns.a2") }}</my-headertitle>
        <div class="info">
          <div class="info-left">
            <span>{{ $t("trafficpatterns.a3") }}</span>
            <span>{{ data.accessRoleName }}</span>
            <a-icon type="form" :style="{ fontSize: '15px', color: '#7682CE', 'margin-left': '15px'}" @click="openNameModal"/>
          </div>
          <div>
            <span>{{ $t("trafficpatterns.a4") }}</span>
            <span>{{ data.accessRoleNo }}</span>
          </div>
        </div>
        <div class="main-table" v-table-size="tableSize">
          <my-tabletitle>
            {{ $t("trafficpatterns.a5") }}
            <template v-slot:name>
              <a-popover placement="bottomRight">
                <template slot="content">
                  <a-row style="width: 350px">
                    <a-col v-for="(item, index) in displayContent" :key="index" :span="12">
                      <a-checkbox :disabled="item.disabled" v-model="item.select">
                        {{ item.title }}
                      </a-checkbox>
                    </a-col>
                  </a-row>
                </template>
                <template slot="title">
                  <span>{{ $t("alarm.a50") }}</span>
                </template>
                <a-button type="primary" size="small">
                  {{ $t("alarm.a50") }}
                </a-button>
              </a-popover>
            </template>
          </my-tabletitle>
          <a-table :columns="column" :dataSource="data.arDeviceList" :pagination="pagination" :scroll="size" :rowKey="(record) => record.id"></a-table>
        </div>
      </div>
      <div class="main-show" v-else>
        <a-icon type="select" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{ $t("trafficpatterns.a6") }}</span>
      </div>
      <div class="footer" v-if="id">
        <a-button class="button" type="primary" @click="openStaffInfo">
          {{ $t('trafficpatterns.a46') }}
        </a-button>
        <a-button class="button" type="primary" @click="copy">
          {{ $t("trafficpatterns.a7") }}
        </a-button>
        <a-button class="button" type="primary" @click="toTerminalEditor">
          {{ $t("trafficpatterns.a8") }}
        </a-button>
      </div>
    </div>
    <!-- 通行模式复制对话框 -->
    <a-modal :title="$t('trafficpatterns.a7')" :width="300" centered :visible="visible" :getContainer="() => this.$refs.trafficpatterns" :destroyOnClose="true" @cancel="cancelCopyMode" v-drag>
      <template slot="footer">
        <a-button key="submit" @click="cancelCopyMode" :disabled="disabled">
          {{ $t("doortimetable.a23") }}
        </a-button>
        <a-button key="back" type="primary" @click="setCopyMode" :disabled="disabled">
          {{ $t("doortimetable.a22") }}
        </a-button>
      </template>
      <div v-loading="confirmLoading" :element-loading-text="$t('trafficpatterns.a1')">
        <div style="padding-bottom: 10px">
          <span>{{ $t("trafficpatterns.a9") }}</span>
        </div>
        <my-select style="width: 200px" :list="list" :placeholder="$t('trafficpatterns.a6')" @select="selectCopyKey" :isActive="isActive"></my-select>
      </div>
    </a-modal>
    <!-- 通行模式名称修改 -->
    <a-modal v-model="nameVisible" :title="$t('trafficpatterns.a43')" :width="300" centered :destroyOnClose="true" @cancel="modalNameCancel" v-drag>
      <template slot="footer">
        <a-button key="submit" @click="modalNameCancel" :disabled="disabled">
          {{ $t("doortimetable.a23") }}
        </a-button>
        <a-button key="back" type="primary" @click="nameUpdate" :disabled="disabled">
          {{ $t("doortimetable.a22") }}
        </a-button>
      </template>
      <div v-loading="loadingOK" :element-loading-text="$t('trafficpatterns.a1')" class="modelName">
        <a-form-model ref="ruleForm" :model="form" layout="horizontal" :rules="rules">
          <a-form-model-item :label="$t('trafficpatterns.a3')" prop="modelName">
            <a-input v-model="form.modelName" allow-clear :maxLength="30" ref="input" :placeholder="$t('trafficpatterns.a44')" v-InputText/>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!-- 通行权限人员信息 -->
    <my-accessStaffInfo :visible="staffInfoVisible" @cancel="staffInfoCancel" :accessRoleId="accessRoleId"/>
  </div>
</template>

<script>
import tabletitle from '../../components/Title/tabletitle'
import headerTitle from '../../components/Title/headerTitle'
import accessStaffInfo from './components/accessStaffInfo.vue'
import { getTPList, getTPData, passCopy, accessRoleName } from '../../api/door'
import { tableSort, tableTitleDisplay, tableIndexDisplay } from '../../utils/utils'
import select from '../../components/Select/select'

export default {
  components: {
    'my-tabletitle': tabletitle,
    'my-headertitle': headerTitle,
    'my-select': select,
    'my-accessStaffInfo': accessStaffInfo
  },
  data() {
    return {
      searchValue: "",
      list: [],
      menuSelect: undefined,
      searchList: [],
      id: undefined,
      loading: false,
      accessRoleId: undefined,
      data: {},
      form: {
        modelName: "",
      },
      disabled: false,
      visible: false,
      staffInfoVisible: false,
      confirmLoading: false,
      copyKey: undefined,
      state: false,
      nameVisible: false,
      modelName: "",
      isActive: undefined,
      loadingOK: false,
      size: { x: 1300, y: 250 },
      pagination: {
        total: 0, //数据总数
        pageSize: 30, //每页中显示10条数据
        showSizeChanger: false, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `${total} ${this.$t("trafficpatterns.a27")}`, //分页中显示总的数据
        showQuickJumper: false, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {
          id: "1",
          title: "NO",
          dataIndex: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          width: 60,
          align: "center",
        },
        {
          id: "2",
          title: this.$t("trafficpatterns.a28"),
          dataIndex: "customerName",
          sorter: (a, b) => {
            return tableSort(a, b, "customerName");
          },
          width: 120,
        },
        {
          id: "3",
          title: this.$t("trafficpatterns.a29"),
          dataIndex: "siteName",
          sorter: (a, b) => {
            return tableSort(a, b, "siteName");
          },
          width: 120,
        },
        {
          id: "4",
          title: this.$t("trafficpatterns.a30"),
          dataIndex: "tenantName",
          sorter: (a, b) => {
            return tableSort(a, b, "tenantName");
          },
          width: 130,
        },
        {
          id: "5",
          title: this.$t("trafficpatterns.a31"),
          dataIndex: "name",
          sorter: (a, b) => {
            return tableSort(a, b, "name");
          },
          width: 130,
        },
        {
          id: "6",
          title: this.$t("trafficpatterns.a32"),
          dataIndex: "credentialReaderName",
          sorter: (a, b) => {
            return tableSort(a, b, "credentialReaderName");
          },
          width: 150,
        },
        {
          id: "7",
          title: this.$t("trafficpatterns.a33"),
          dataIndex: "EQUIPMENT_NAME2",
          sorter: (a, b) => {
            return tableSort(a, b, "EQUIPMENT_NAME2");
          },
          width: 100,
        },
        {
          id: "8",
          title: this.$t("trafficpatterns.a34"),
          dataIndex: "alertSet",
          customRender: (text, record, index) =>
            record.alertSet == 0
              ? this.$t("trafficpatterns.a35")
              : this.$t("trafficpatterns.a36"),
          sorter: (a, b) => {
            return tableSort(a, b, "alertSet");
          },
          width: 100,
        },
        {
          id: "9",
          title: this.$t("trafficpatterns.a37"),
          dataIndex: "scheduleName",
          sorter: (a, b) => {
            return tableSort(a, b, "scheduleName");
          },
          width: 130,
        },
        {
          id: "10",
          title: this.$t("trafficpatterns.a38"),
          dataIndex: "calendarName",
          sorter: (a, b) => {
            return tableSort(a, b, "calendarName");
          },
          width: 100,
        },
      ],
      column: [],
      displayContent: JSON.parse(localStorage.getItem("trafficpatterns_tableTitle")) || [],
      rules: {
        modelName: [
          {
            required: true,
            message: this.$t("trafficpatterns.a44"),
            trigger: "change",
          },
        ],
      }
    }
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {
      this.loading = true
      let number = this.id.split(",")
      this.accessRoleId = number[1]
      let data = { siteId: this.$route.query.id, accessRoleId: number[1] }
      this.getData(data)
    },
    // 监听搜索框输入内容查找对应的数据
    searchValue: {
      handler(newValue) {
        let list = this.searchList;
        let searchList = list.filter(
          (row) => row.title.indexOf(newValue) !== -1
        );
        this.list = searchList;
      },
      deep: true,
    },
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns;
        const name = "trafficpatterns_tableTitle";
        const data = tableIndexDisplay(name, columns, newValue);
        this.column = data;
      },
      deep: true,
      immediate: true,
    },
  },
  // 获取侧边栏数据
  mounted() {
    this.loading = true;
    let data = { siteId: this.$route.query.id }
    getTPList(data)
      .then((res) => {
        console.log(res)
        this.loading = false;
        let { data } = res;
        this.list = data.children[0].children;
        this.searchList = data.children[0].children;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      })
    // 表格表头
    const name = 'trafficpatterns_tableTitle'
    const columns = this.columns
    const tableTitle = tableTitleDisplay(name, columns)
    if (tableTitle.columns == undefined) {
      this.displayContent = tableTitle.displayContent
    } else {
      this.column = tableTitle.columns
      this.displayContent = tableTitle.displayContent
    }
  },
  methods: {
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById("trafficpatterns")
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName("main-table")[0].clientHeight
        const title = document.getElementsByClassName("ant-table-thead")[0].clientHeight
        const tableHeight = height - 118.5 - title
        this.size.y = tableHeight
      }
    },
    // 页面数据加载
    getData(data) {
      this.loading = true
      getTPData(data)
        .then((res) => {
          this.loading = false
          let { data } = res
          this.data = data
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        });
    },
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data, index) {
      this.id = data.key
      this.menuSelect = index
    },
    // 通行模式页跳转至认证终端编辑页
    toTerminalEditor() {
      this.$router.push({
        path: '/homepage/door/terminalEditor',
        query: { id: this.$route.query.id, accessRoleId: this.accessRoleId }
      })
    },
    // 通行模式复制按钮
    copy() {
      this.visible = true
    },
    // 通行模式复制的确定按钮
    setCopyMode() {
      if (this.isActive == undefined) {
        this.$error({
          title: this.$t("trafficpatterns.a10"),
          content: this.$t("trafficpatterns.a11"),
          centered: true
        })
      } else {
        this.confirmLoading = true
        this.disabled = true
        let data = { srcID: this.copyKey, targetID: this.accessRoleId }
        passCopy(data)
          .then((res) => {
            // console.log(res)
            this.confirmLoading = false
            this.disabled = false
            if (res.errorCode == "00") {
              this.copyKey = undefined
              let data = {
                siteId: this.$route.query.id,
                accessRoleId: this.accessRoleId
              }
              this.getData(data)
              this.cancelCopyMode()
              this.$message.success(res.msg)
            } else if (res.errorCode == "02") {
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {
            this.confirmLoading = false
            this.disabled = false
            console.log(err)
          });
      }
    },
    // 通行模式复制的取消按钮
    cancelCopyMode() {
      this.visible = false
      this.isActive = undefined
    },
    // 选择通行模式
    selectCopyKey(val) {
      this.isActive = val
      this.copyKey = val.key.split(",")[1]
    },
    // 更新名称
    nameUpdate() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.disabled = true
          this.loadingOK = true
          let data = { siteId: this.$route.query.id, accessRoleList: [{ name: this.form.modelName, id: this.accessRoleId }]}
          accessRoleName(data)
            .then((res) => {
              console.log(res)
              this.disabled = false
              this.loadingOK = false
              if (res.errorCode == '00') {
                this.$message.success(res.msg)
                this.nameVisible = !this.nameVisible
                for (let i = 0; i < this.list.length; i++) {
                  if (this.id == this.list[i].key) {
                    const val = this.list[i].title.split("-")[0]
                    const name = val + "-" + this.form.modelName
                    this.list[i].title = name
                    this.data.accessRoleName = this.form.modelName
                  }
                }
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {
              console.log(err)
              this.loadingOK = false
              this.disabled = false
            })
        } else {
          return false
        }
      })
    },
    // 打开通行模式名称对话框
    openNameModal() {
      this.nameVisible = true
      this.form.modelName = this.data.accessRoleName
      setTimeout(() => {this.$refs.input.focus()}, 5)
    },
    // 关闭通行模式名称修改对话框
    modalNameCancel() {
      this.nameVisible = !this.nameVisible
    },
    // 打开通行权限人员信息对话框
    openStaffInfo(){
      this.staffInfoVisible = true
    },
    // 关闭通行权限人员信息对话框
    staffInfoCancel(e){
      this.staffInfoVisible = false
    }
  }
}
</script>
<style scoped>
#trafficpatterns {
  height: 100%;
  display: flex;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: hidden;
}
.aside-search {
  padding: 15px 0px 15px 20px;
}
.aside-menu {
  padding-left: 20px;
  border-right: 1px solid rgba(12, 12, 12, 0.2);
  height: calc(100% - 62px);
  overflow: auto;
}
.main {
  width: 100%;
  height: calc(100% - 55px);
  padding: 0px 20px;
  overflow: hidden;
}
.main-table {
  height: calc(100% - 101px);
}
.li-item {
  position: relative;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
  padding: 0px 20px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  overflow: hidden;
  transition: color 0.3s;
}
.li-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #7682ce;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: 0.15s;
  content: "";
}
.li-item:hover {
  color: #7682ce;
}
.li-item-select {
  position: relative;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-overflow: ellipsis;
  padding: 0px 20px;
  background-color: #f0f4ff;
  white-space: nowrap;
  color: #7682ce;
  cursor: pointer;
  overflow: hidden;
}
.li-item-select::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #7682ce;
  transform: scaleY(1);
  opacity: 1;
  transition: 0.15s;
  content: "";
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.info-left {
  display: flex;
  align-items: center;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
.modelName {
  height: 80px;
  display: flex;
  align-items: center;
}
.button {
  margin: 0px 10px;
}
</style>