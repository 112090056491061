<!-- 门禁模块--设置--通行模块设置--通行权限人员信息组件 -->
<template>
  <div id="accessStaffInfo">
    <a-modal :visible="visible" :title="$t('accessStaffInfo.a1')" :width="tableWidth - 300" centered :destroyOnClose="true" @cancel="staffInfoCancel" v-drag>
      <!-- 对话框底栏 -->
      <template slot="footer">
        <a-button key="submit" @click="staffInfoCancel" :disabled="disabled">
          {{ $t("accessStaffInfo.a2") }}
        </a-button>
      </template>
      <div v-loading="loading" :element-loading-text="$t('accessStaffInfo.a3')" class="modelName" v-table-size="tableSize">
        <!-- 标题栏 -->
        <my-tabletitle>
          <!-- 人员信息列表（标题） -->
          {{ $t("accessStaffInfo.a4") }}
          <template v-slot:name>
            <!-- 人员信息导出功能 -->
            <a-dropdown>
              <a-menu slot="overlay" @click="handleMenuClick">
                <!-- 当前页导出按钮 -->
                <a-menu-item :key="1">{{ $t("accessStaffInfo.a5") }}</a-menu-item>
                <!-- 全部导出按钮 -->
                <a-menu-item :key="0">{{ $t("accessStaffInfo.a6") }}</a-menu-item>
              </a-menu>
              <!-- 人员信息导出按钮 -->
              <a-button type="primary" ghost size="small" style="margin-right:15px"> {{ $t("accessStaffInfo.a7") }} <a-icon type="down" /> </a-button>
            </a-dropdown>

            <!-- 显示内容功能 -->
            <a-popover placement="bottomRight" :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <template slot="content">
                <a-row style="width: 350px">
                  <a-col v-for="(item, index) in displayContent" :key="index" :span="12">
                    <a-checkbox :disabled="item.disabled" v-model="item.select">  
                      {{ item.title }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </template>
              <template slot="title">
                <span>{{ $t("accessStaffInfo.a8") }}</span>
              </template>
              <!-- 显示内容按钮 -->
              <a-button type="primary" size="small"> {{ $t("accessStaffInfo.a8") }} </a-button>
            </a-popover>
          </template>
        </my-tabletitle>

        <!-- 人员信息表格 -->
        <a-table :columns="column" :dataSource="tableData" :scroll="size" :pagination="pagination" :rowKey="(record) => record.personId" size="middle">
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from '../../../components/Title/tabletitle'
import { getAccessRoleData, exportAccessRoleData } from '../../../api/door'
import { tableSort, tableTitleDisplay, tableIndexDisplay} from '../../../utils/utils'

export default {
  name:'accessStaffInfo',
  components: {
    'my-tabletitle': tabletitle
  },
  props:{
      visible:{
        type: Boolean,
        default: false
      },
      accessRoleId:{
        type: String,
        default: ''
      }
  },
  data(){
      return {
        loading:false,
        staffInfoVisible:true,
        disabled:false,
        tableData: [],
        size: { x: 2300, y: 240 },
        pagination: {
          total: 0, //数据总数
          pageSize: 30, //每页中显示10条数据
          showSizeChanger: false, //是否可以改变pagesize
          pageSizeOptions: ['5', '10', '50', '100'], //每页中显示的数据
          showTotal: (total) => `${total} ${this.$t('doorDetails.a129')}`, //分页中显示总的数据
          showQuickJumper: false, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          // hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
            this.pagination.defaultCurrent = current
            this.pagination.pageSize = pageSize
            if(this.$platform.spider === 'NEW'){
              this.getTableData()
            }
          },
          onChange: (current, size) => {
            this.pagination.defaultCurrent = current
            this.pagination.pageSize = size
            if(this.$platform.spider === 'NEW'){
              this.getTableData()
            }
          }
        },
        columns: [
          {
            id: '1',
            title: 'NO',
            dataIndex: 'NO',
            customRender: (text, record, index) =>`${(this.pagination.defaultCurrent - 1) * this.pagination.pageSize + index + 1}`,
            width: 60,
            align: 'center',
            fixed: 'left'
          },
          {
            id: '2',
            title: this.$t('accessStaffInfo.a9'),
            dataIndex: 'managementCode',  // 管理代码
            sorter: (a, b) => { return tableSort(a, b, 'managementCode') },
            width: 130,
            align: 'center'
          },
          {
            id: '3',
            title: this.$t('accessStaffInfo.a10'),
            dataIndex: 'name',  // 姓名
            sorter: (a, b) => { return tableSort(a, b, 'name') },
            width: 110,
            align: 'center'
          },
          {
            id: '4',
            title: this.$t('accessStaffInfo.a11'),
            dataIndex: 'kana',  // 部门
            sorter: (a, b) => { return tableSort(a, b, 'kana') },
            width: 250,
            align: 'center'
          },
          {
            id: '5',
            title: this.$t('accessStaffInfo.a12'),
            dataIndex: 'cardFaceNo',  // 卡编号
            sorter: (a, b) => { return tableSort(a, b, 'cardFaceNo') },
            width: 130
          },
          {
            id: '14',
            title: this.$t('accessStaffInfo.a13'),
            dataIndex: 'nationalityName',  // 国籍
            sorter: (a, b) => { return tableSort(a, b, 'nationalityName') },
            width: 100,
            align: 'center'
          },
          {
            id: '6',
            title: this.$t('accessStaffInfo.a14'),
            dataIndex: 'resistTime', // 注册日期
            sorter: (a, b) => { return tableSort(a, b, 'resistTime') },
            width: 130,
            align: 'center'
          },
          {
            id: '7',
            title: this.$t('accessStaffInfo.a15'),
            dataIndex: 'related_building',  // 关联建筑
            sorter: (a, b) => { return tableSort(a, b, 'related_building') },
            align: 'center'
          },
          {
            id: '8',
            title: this.$t('accessStaffInfo.a16'),
            dataIndex: 'personGr1',  // 所属1
            sorter: (a, b) => { return tableSort(a, b, 'personGr1') },
            width: 130,
            align: 'center'
          },
          {
            id: '9',
            title: this.$t('accessStaffInfo.a17'),
            dataIndex: 'personGr2',  // 所属2
            sorter: (a, b) => { return tableSort(a, b, "'personGr2'") },
            width: 300,
            align: 'center'
          },
          {
            id: '10',
            title: this.$t('accessStaffInfo.a18'),
            dataIndex: 'personGr3',  // 所属3
            sorter: (a, b) => { return tableSort(a, b, 'personGr3') },
            width: 200,
            align: 'center'
          },
          {
            id: '11',
            title: this.$t('accessStaffInfo.a19'),
            dataIndex: 'personGr4',  // 所属4
            sorter: (a, b) => { return tableSort(a, b, "'personGr4'") },
            width: 180,
            align: 'center'
          },
          {
            id: '12',
            title: this.$t('accessStaffInfo.a20'),
            dataIndex: 'personGr5',  // 所属5
            sorter: (a, b) => { return tableSort(a, b, 'personGr5') },
            width: 180,
            align: 'center'
          }
        ],
        column: [],
        displayContent: JSON.parse(localStorage.getItem('accessStaffInfo_tableTitle')) || [],
        tableWidth: window.innerWidth,
      }
  },
  watch:{
    // 监听表格表头显示内容生成表格表头
    displayContent: {
      handler(newValue) {
        const columns = this.columns
        const name = 'accessStaffInfo_tableTitle'
        const data = tableIndexDisplay(name, columns, newValue)
        this.column = data
      },
      deep: true,
      immediate: true
    },
    visible:{
      handler(newValue) {
        if(newValue){
          this.getTableData()
        }else{
          return false
        }
      }
    }
  },
  mounted(){
    // 表格表头
    console.log(this.tableWidth)
    const name = 'accessStaffInfo_tableTitle'
    const columns = this.columns
    const data = tableTitleDisplay(name, columns)
    if (data.columns == undefined) {
      this.displayContent = data.displayContent
    } else {
      this.column = data.columns
      this.displayContent = data.displayContent
    }
  },
  methods:{
    // 表格高度计算
    tableSize(){
      const indexDom = document.getElementsByClassName("modelName")[0]
      if (indexDom !== undefined) {
        const height = document.getElementsByClassName("modelName")[0].clientHeight
        const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight
        const tableHeight = height - 100 - title
        this.size.y = tableHeight
      }
    },
    // 对话框点击遮罩层或右上角叉或取消按钮的回调
    staffInfoCancel(){
      this.$emit('cancel')
    },
    // 表格数据获取
    getTableData(){
      const data = { accessRoleId: Number(this.accessRoleId), pageNum: this.pagination.defaultCurrent, pageSize: this.pagination.pageSize }
      console.log(data)
      this.loading = true
      getAccessRoleData(data)
      .then(res => {
        console.log(res)
        const { data: { list, total } } = res
        this.tableData = list
        this.pagination.total = total
        this.loading = false
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    // 获取导出人员信息 type：0全部数据导出，1当前页数据导出
    handleMenuClick(e){
      this.loading = true
      const type = e.key
      const accessRoleWithPersonBean = {
        pageNum: this.pagination.defaultCurrent,
        pageSize: this.pagination.pageSize,
        accessRoleId: Number(this.accessRoleId)
      } 
      exportAccessRoleData(accessRoleWithPersonBean,type)
      .then(res => {
        // console.log(res)
        this.loading = false
        const filename = decodeURIComponent(res.headers['content-disposition']).split(';')[1].split('=')[1]
        const file = res.data
        this.download(file,filename)
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    // 文件导出
    download(file,filename) {
        let url = window.URL.createObjectURL(file)
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
    }
  }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.modelName{
  height: 4.5rem;
}
</style>